import React from 'react'
import cx from 'classnames'

import { Marqy } from 'marqy'
import 'marqy/styles.css'

export interface MarqueeProps {
  data: {
    marqueeText: any[]
    color?: string
    subTitle?: string
  }
}

export const Marquee = ({ data }: MarqueeProps) => {
  const {
    marqueeText,
    color,
    subTitle
  } = data
  return (
    <div className={cx('xv  of--hidden pr bt ', `bg--${color}`)}>
      <div className='of--hidden tc pt1 pb1 marquee__module pt3--800 pb3--800 outer mxa py2 al p x'>
        <h5 className='founders p3 s16 s18--800  color--lite-yellow'>{subTitle}</h5>
        <div className='x df ws--nowrap'>
          <Marqy speed='0.4'>
            {marqueeText.map((text, i) => (
              <span key={i} className='h1 s80--1000 ws--nowrap s120--1000 ml4 mr4 color--lite-yellow founders'>&nbsp;{text} </span>
            ))}
          </Marqy>
        </div>
      </div>
    </div>
  )
}

export default Marquee